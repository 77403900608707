<template>
      <div class="dashboard-grid">
        <div class="dashboard-grid-col-double">

            <div class="content-container relative">
                <SensorsMap class="m-0 p-0 mapcomponent" />
                <div class="absolute top-0 left-0">
                    <p class="ml-3 mt-3 text-sm text-primary">MAP</p>
                    <h4 class="ml-3 mt-0">Übersicht</h4>
                </div>
            </div>

            <div class="horizontal-content-dashboard align-items-start flex-wrap mt-3 gap-1">
                <div class="content-area">
                    <ChartWidget purposegroup="analyse" />
                </div>
                <div class="content-area">
                    <ChartWidget purposegroup="metadata" />
                </div>
            </div>
                
        </div>

        <div class="dashboard-grid-col-single">
            <div class="flex flex-auto flex-column gap-1"  >
                <div class="hidden xl:block surface-0 p-4 border-round relative" style="height:100px;overflow:hidden">
                    <div class="text-xl font-bold">Live Status</div>
                    <div style="overflow:hidden">
                        <LiveStatus class="livestatus" style="top: -45px" />
                    </div>
                </div>
                <div class="content-area">
                    <IssuesList class="p-3" @issueItemSelected="onClickIssueItem" />
                </div>
            </div>

        </div>

      </div>
</template>


<script>
import { useRouter } from "vue-router";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";
import SensorsMap from './SensorsMap.vue';
import IssuesList from './IssuesList.vue';
import ChartWidget from './ChartWidget.vue';
import LiveStatus from './LiveStatus.vue';

export default {
  name: "Dashboard",
  components: {
    SensorsMap, IssuesList, ChartWidget, LiveStatus
  },
  setup(/*props, context*/) {
    const router = useRouter();
    const store = useStore();
    //const toast = useToast();
    //const loading = ref(true);
    //const restService = useRestApi();
    //const m_Installations = ref([]);
    //const rightbarVisible = ref(true);

    //const initInstallation = () => { store.dispatch('initInstallation'); }
    
    let m_componentMounted = false;
    let m_componentInitialized = false;
    
    onMounted(() => {
        m_componentMounted = true;
        initWhenReady();

        if (!store.getters.isloggedin) {
            console.log("DP: Not logged in");
            router.push("/login");
        }
    });

    watch(() => store.getters.isready, function () { initWhenReady(); });

    const onClickIssueItem = (feed) => {
        //console.log("Navigate to feed " + feed.pkey);
        router.push({name:"feed", params: {displayfeedkey: feed.pkey} });
    }

    const initWhenReady = async () => {
        //console.log("called DashPage::initWhenReady: initialized=" + m_componentInitialized + ", mounted=" + m_componentMounted + ", ready="+store.getters.isready);
        if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
        m_componentInitialized = true;
        
        // do some initialization here
        if (store.getters.sensorscount == 0) {
            store.dispatch("refreshSensors");
        }

        if (store.getters.feedscount == 0) {
            store.dispatch("refreshFeeds");
        }
    }

    return { router, onClickIssueItem };
  },
};
</script>

<style scoped>

a {
    text-decoration: none; 
    color: var(--text-color, black);
}

.mapcomponent {
  min-height: 0;    /* Let the content overflow */
  flex: 1;          /* Fill the available space */
}

.livestatus {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

</style>