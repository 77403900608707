<template>
  <div class="content-area">

    <div v-if="enableHeader" class="flex flex-row flex-wrap justify-content-between mb-4 gap-1">
      <div class="text-2xl font-bold">Meldungen</div>
      <div class="flex">
        <span class="p-input-icon-left">
          <i class="pi pi-filter" />
          <InputText type="text" class="p-inputtext-sm" v-model="m_sSearchText" placeholder="Filtern" @input="showIssues()" />
        </span>
      </div>
    </div>

    <hr v-if="enableHeader" />

    <div v-if="enableHeader" class="flex flex-column flex-wrap justify-content-center mb-2">
        <div class="flex flex-row flex-wrap justify-content-center gap-filters">
            <FilterButton v-for="(f, index) in m_filterOptions" :key="index" :label="f.name" 
                class="filterbutton"
                :selectionoption="f.option"
                :selected="false"
                @filter-selected="onFilterSelected"
                @filter-deselected="onFilterDeSelected" />
            <FilterButton label="Inaktiv" 
                class="filterbutton"
                :selectionoption="0"
                :selected="false"
                @filter-selected="m_bShowInactive = true; showIssues();"
                @filter-deselected="m_bShowInactive = false; showIssues();" />
        </div>
    </div>

    <hr v-if="enableHeader" />

    <div class="scrollable-flex">
        <issue-list-item v-for="(issue) in m_Issues" 
          class="issue-list-item p-3 ml-0 mr-2 mb-4 cursor-pointer border-round"
          :key="issue.pkey" 
          :issue="issue"
          :class="{'selected-list-item' : isSelected(issue), 'non-selected-list-item' : !isSelected(issue)}"
          @click="onClickItem(issue)">
        </issue-list-item>
    </div>

  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import FilterButton from './FilterButton.vue';
import IssueListItem from './IssueListItem.vue';
import { ref /*, computed */, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "IssuesList",
  components: { FilterButton, IssueListItem, InputText },
  props: {
    enableHeader : {
      type: Boolean,
      default: true
    },
    enableSelected : {
      type: Boolean,
      default: false
    },
    selecteditempkey : {
      type: Number,
      default: 0
    },
    sensorkey : {
      type: Number,
      default: 0
    }
  },
  emits : ["issueItemSelected", "update:selecteditempkey"],
  setup(props, context) {
    const store = useStore();
    const m_bSearchIsActive = ref(false);
    const m_sSearchText = ref("");
    const m_selectedFilterOptions = ref([]);
    const m_bShowInactive = ref(false);
    const m_SelectedIssue = ref(null);

    const m_filterOptions = ref([
        {name: 'System', option: 2},
        {name: 'Pflanze', option: 1},
        {name: 'Metadaten', option: 3},
        {name: 'Handlungsempfehlung', option: 4}
    ]);

    const m_Issues = ref([ 
    ]);

    let m_componentMounted = false;
    let m_componentInitialized = false;
    
    onMounted(() => {
        m_componentMounted = true;
        initWhenReady();
    });

    watch(() => store.getters.isready, function () { initWhenReady(); });
    watch(() => store.getters.feeds, function () { showIssues(); });
    watch(() => props.sensorkey, function () { showIssues(); });
    watch(() => props.selecteditempkey, function () { m_SelectedIssue.value = store.getters.findFeedByPkey(props.selecteditempkey); });

    const initWhenReady = async () => {
      if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
      m_componentInitialized = true;

      m_SelectedIssue.value = store.getters.findFeedByPkey(props.selecteditempkey);
      showIssues();
    }

    const onClickItem = (it) => {
        if (props.enableSelected) {
          m_SelectedIssue.value = it;
          context.emit('update:selecteditempkey', it.pkey);
        }
        context.emit('issueItemSelected', it);
    }

    const isSelected = (it) => {
        if (props.enableSelected) {
          if (m_SelectedIssue.value) {
            return (it.pkey > 0 && it.pkey === m_SelectedIssue.value.pkey);
          }
        } 
        return false;
    }

    const matchesSearch = (feed) =>
    {
      let txt = m_sSearchText.value.toLowerCase();
      let plantid = feed.plantid ?? "";
      
      // try to find a match inside label, message or plant
      let hasMatch = (feed.label.toLowerCase().includes(txt) || 
              feed.message.toLowerCase().includes(txt) ||
              plantid.toLowerCase().includes(txt) );
      
      // if there is still no match, check if the sensor or gateway id match the text
      if (!hasMatch)
      {
          let sensorkey = feed.sensorkey ?? 0;
          let sensor = store.getters.findSensorByPkey(sensorkey);
          if (sensor) hasMatch = sensor.id.toLowerCase().includes(txt);
      }

      if (!hasMatch)
      {
          let gatewaykey = feed.gatewaykey ?? 0;
          let gw = store.getters.findGatewayByPkey(gatewaykey);
          if (gw) hasMatch = gw.id.toLowerCase().includes(txt);
      }

      return hasMatch;
    }

    // Filter the issues and add them to the array of issues to show
    const showIssues = () => {
      let feeds = [];
      m_Issues.value = []; // clear the previous issues
      
      //console.log("Show filtered by "+props.sensorkey);
      if (props.sensorkey > 0) {
        feeds = store.getters.getFeedsOfSensor(props.sensorkey); // filter by sensor
      }
      else { // don't filter by sensor
        feeds = store.getters.feeds;
      }

      // appy full text search first
      if (m_sSearchText.value.length > 0) {
        feeds = feeds.filter(matchesSearch);
      }

      // Apply the category filters
      feeds.forEach(f => {
        if (f.active || m_bShowInactive.value) {
          if (m_selectedFilterOptions.value.length == 0 && (!m_bShowInactive.value || (m_bShowInactive.value && !f.active)) ) // if no filter is selected
          {
            m_Issues.value.push(f);
          }
          else if (m_selectedFilterOptions.value.includes(f.category)) { // filter is selected
            m_Issues.value.push(f);
          }
        }
      });
    };

    const onFilterSelected = (option) => {
        m_selectedFilterOptions.value.push(option);
        //console.log("Selected options " + JSON.stringify(m_selectedFilterOptions.value));
        showIssues();
    };

    const onFilterDeSelected = (option) => {
        //console.log("Got change event: " + option);
        m_selectedFilterOptions.value = m_selectedFilterOptions.value.filter(item => item !== option);
        showIssues();
        //console.log("Selected options " + JSON.stringify(m_selectedFilterOptions.value));
    };

    /*const onSelectFilterOption = (filterOption) => {
        m_selectedFilterOptions.value = filterOption;
    };*/

    return { m_bSearchIsActive,
            m_sSearchText,
            m_filterOptions,
            m_selectedFilterOptions,
            onFilterSelected, 
            onFilterDeSelected,
            showIssues,
            m_Issues,
            m_bShowInactive,
            onClickItem, isSelected
             };
  },
};
</script>

<style scoped>

</style>

