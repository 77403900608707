<template>
    <div>
        <div class="grid grid-nogutter">
          <div class="col-fixed mr-2">
            <Avatar icon="pi pi-android" class="surface-b" size="large" /> 
          </div>
          <div class="col">
              <div class="timestamp text-sm text-dimmed"> {{timestampText}}</div>
              <div class="title text-lg font-bold mb-1"> {{issue.label}}</div>
              <div class="message">{{issue.message}}</div>
          </div>
        </div>

        <div class="mt-3">
            <history-entry-reaction v-for="(reaction) in issue.reactions" :key="reaction.pkey" :reaction="reaction" class="ml-3 mb-2" />
        </div>

    </div>
</template>

<script>
import { computed, onMounted} from  "vue";
import { timestampToString } from "../epcommon";
import HistoryEntryReaction from "./HistoryEntryReaction.vue";

export default ({
    name: "HistoryEntryIssue",
    components: { HistoryEntryReaction },
    props: {
        issue: {
            type: Object,
            default: () => { return {pkey:0,label:"",message:"",timestamp:"",reactions:[]} }
        }
    },
    setup(props) {

        onMounted(() => {
        });

        const timestampText = computed(() => {
            return timestampToString(props.issue.timestamp);
        });

        return { timestampText }
    }
})
</script>

<style scoped>

</style>

