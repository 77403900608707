<template lang="">
<div>
    <slot></slot>
</div>
</template>

<script>
import {
    inject,
    provide,
    onUnmounted,
    onMounted,
    watch,
    computed
} from 'vue'

import TileLayer from 'ol/layer/Tile';
import usePropsAsObjectProperties from 'vue3-openlayers/src/composables/usePropsAsObjectProperties';
import BaseLayer from 'vue3-openlayers/src/components/layers/BaseLayer.vue';

export default {
    extends: BaseLayer,
    name: 'ol-tile-layer',
    setup(props) {

        const map = inject('map');
        const overViewMap = inject('overviewMap', null);

        const {
            properties
        } = usePropsAsObjectProperties(props);

        
        const tileLayer = computed(() => {
            //console.log("Tile layer created");
            let l = new TileLayer(properties);

            l.on('postrender', function (evt) {
                //console.log("GOT ONE POSTRENDER EVENT");
                //console.log(evt);
                evt.context.globalCompositeOperation = 'color';
                evt.context.fillStyle = 'rgb(49,53,69)';
                evt.context.fillRect(0, 0, evt.context.canvas.width, evt.context.canvas.height);
                evt.context.globalCompositeOperation = 'overlay';
                evt.context.fillStyle = 'rgb(0,0,0)';
                evt.context.fillRect(0, 0, evt.context.canvas.width, evt.context.canvas.height);
                evt.context.globalCompositeOperation = 'source-over';
            });

            return l;
        });

        const applyTileLayer = () => {

            if (overViewMap != null) {
                overViewMap.value.getOverviewMap().addLayer(tileLayer.value);
                overViewMap.value.changed();
            } else {
                map.addLayer(tileLayer.value);
            }
        };

        const removeTileLayer = () => {

            if (overViewMap != null) {
                overViewMap.value.getOverviewMap().removeLayer(tileLayer.value);
                overViewMap.value.changed();
            } else {
                map.removeLayer(tileLayer.value);
            }
        };

        if (overViewMap != null) {
            watch(overViewMap, () => {
                removeTileLayer();
                applyTileLayer();

            });
        }

        onMounted(() => {
            applyTileLayer();
        });

        onUnmounted(() => {
            removeTileLayer();
        });

        provide('tileLayer', tileLayer);

        return {
            tileLayer
        }
    },
    props: {  
        preload: {
            type: Number,
            default: 1
        },
    }
}
</script>

<style lang="">

</style>
