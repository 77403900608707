import { createApp, h} from 'vue';
import store from "./store";
import AppWrapper from './components/AppWrapper.vue';
import PrimeVue from 'primevue/config';
import router from './routes'
import ToastService from 'primevue/toastservice';
import OpenLayersMap from 'vue3-openlayers';

import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
//import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
//import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import CascadeSelect from 'primevue/cascadeselect';
//import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
//import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
//import Galleria from 'primevue/galleria';
//import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
//import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
//import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
//import MultiSelect from 'primevue/multiselect';
//import OrderList from 'primevue/orderlist';
//import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
//import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
//import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
//import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
//import SplitButton from 'primevue/splitbutton';
//import Splitter from 'primevue/splitter';
//import SplitterPanel from 'primevue/splitterpanel';
//import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
//import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
//import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
//import Tree from 'primevue/tree';
//import TreeSelect from 'primevue/treeselect';
//import TreeTable from 'primevue/treetable';
//import TriStateCheckbox from 'primevue/tristatecheckbox';

import Kulturenliste from './components/Kulturenliste.vue';
import HistoryEntryReaction from "./components/HistoryEntryReaction.vue";
import HistoryEntryIssue from "./components/HistoryEntryIssue.vue";
import IssueListItem from './components/IssueListItem.vue';
import SensorListItem from './components/SensorListItem.vue';
import SensorsMap from './components/SensorsMap.vue';

import 'vue3-openlayers/dist/vue3-openlayers.css'
import '../public/css/eplant-theme.css'; // theme
import '../public/css/eplant.css';

import 'primevue/resources/primevue.min.css';   //core css
import 'primeicons/primeicons.css';             //icons
import 'primeflex/primeflex.css';
import '../public/css/layout-dark.css';

const prime_locale_de = {
    startsWith: 'Beginnt mit',
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    endsWith: 'Endet mit',
    equals: 'ist gleich',
    notEquals: 'ist nicht gleich',
    noFilter: 'Kein Filter',
    lt: 'Kleiner als',
    lte: 'Kleiner gleich',
    gt: 'Größer als',
    gte: 'Größer gleich',
    dateIs: 'Das Datum ist',
    dateIsNot: 'Das Datum ist nicht',
    dateBefore: 'Das Datum liegt vor',
    dateAfter: 'Das Datum liegt nach',
    clear: 'Löschen',
    apply: 'Anwenden',
    matchAll: 'Alle Schlagwörter enthalten',
    matchAny: 'Mindestens ein Schlagwort enthalten',
    addRule: 'Regel hinzufügen',
    removeRule: 'Regel entfernen',
    accept: 'Ja',
    reject: 'Nein',
    choose: 'Auswahl',
    upload: 'Hochladen',
    cancel: 'Abbrechen',
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fri", "Sam"],
    dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
    monthNames: ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
    monthNamesShort: ["Jan", "Feb", "März", "Apr", "Mai", "Jun","Jul", "Aug", "Sept", "Okt", "Nov", "Dez"],
    today: 'Heute',
    weekHeader: 'KW',
    firstDayOfWeek: 1,
    dateFormat: 'dd.mm.yyyy',
    weak: 'Schwach',
    medium: 'Medium',
    strong: 'Stark',
    passwordPrompt: 'Bitte Passwort eingeben',
    emptyFilterMessage: 'Keine Ergebnisse gefunden',
    emptyMessage: 'No available options'
};

//const app = createApp(App);

const app = createApp({
    render () { return h(AppWrapper); }
});

//app.config.globalProperties.$appState = reactive({ colorScheme: 'light' });

// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router);
app.use(store);
app.use(PrimeVue, { locale : prime_locale_de, ripple: true});
app.use(ConfirmationService);
app.use(ToastService);
app.use(OpenLayersMap);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);


// Register a global custom directive called `v-focus`
/*app.directive('focus', {
    mounted(el) { // When the bound element is mounted into the DOM...
      el.focus(); // Focus the element
    }
  });*/


app.component('HistoryEntryReaction', HistoryEntryReaction);
app.component('HistoryEntryIssue', HistoryEntryIssue);
app.component('Kulturenliste', Kulturenliste);
app.component('IssueListItem', IssueListItem);
app.component('SensorListItem', SensorListItem);
app.component('SensorsMap', SensorsMap);


app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
//app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
//app.component('Calendar', Calendar);
app.component('Card', Card);
//app.component('Carousel', Carousel);
app.component('CascadeSelect', CascadeSelect);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
//app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
//app.component('Galleria', Galleria);
//app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
//app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
//app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
//app.component('MultiSelect', MultiSelect);
//app.component('OrderList', OrderList);
//app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
//app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
//app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
//app.component('Skeleton', Skeleton);
//app.component('SplitButton', SplitButton);
//app.component('Splitter', Splitter);
//app.component('SplitterPanel', SplitterPanel);
//app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
//app.component('TieredMenu', TieredMenu);
//app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
//app.component('Tree', Tree);
//app.component('TreeSelect', TreeSelect);
//app.component('TreeTable', TreeTable);
//app.component('TriStateCheckbox', TriStateCheckbox);

app.mount('#app');
