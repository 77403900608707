import axios from 'axios'
import store from "./store"
import router from "./routes.js"

export default class RESTService {
    
    constructor() {
        axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT_BASEURL;
        axios.defaults.timeout = 9000; // requests must be serviced within 9 seconds

        // Add a request interceptor to add the token
        axios.interceptors.request.use(function (config) {
            // Add authorization header before the request is sent
            
            //console.log("enter request-intercepting: url=" + config?.url);
            //console.log("store accesstoken: " + store.getters.accesstoken );

            if (config?.url == 'token')
            {
                // use refresh token
                if (store.getters.refreshtoken.length > 0)
                {
                    config.headers["Authorization"] = "Bearer " + store.getters.refreshtoken;
                }
                else {
                    console.log("call to " + config?.url + " without token");
                }
            }
            else 
            {
                // use access token
                if (store.getters.accesstoken.length > 0)
                {
                    config.headers["Authorization"] = "Bearer " + store.getters.accesstoken;
                }
                else {
                    console.log("call to " + config?.url + " without token");
                }
            }

            //console.log("exit request interceptingurl=" + config?.url);
            return config;
        }, function (error) {
            console.warn("ERROR in request: " + JSON.stringify(error));
            return Promise.reject(error); // Do something with request error
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error

            console.warn("RESP ERROR: " + JSON.stringify(error));

            if (typeof error.response === 'undefined') {
                console.error('A network error occurred. This could be a CORS issue or a dropped internet connection.');
                error["response"] = {status: 0, data:"A network error or a CORS issue.", headers:""}
            }
            
            if (error.response.status == 401)
            {
                let url = error.response?.config?.url ?? "";

                console.warn("Upps, we've got 401-Unauthorized ("+url+"). Must log in again");
                store.commit("settokens", null); // not authorized - uups, invalid token -> log in again
                router.push('/login');
            }

            return Promise.reject(error);
        });
    }

    async authorize(username, password) {
        let reqPayload = {username:username, password:password};
        let resp = await axios.post(process.env.VUE_APP_API_ENDPOINT_AUTHORIZE, reqPayload);
        return resp.data;
    }

    async refreshToken() {
        let resp = await axios.get('token');
        return resp.data;
    }

    async getInstallations() {
        let resp = await axios.get('inst');
        return resp.data;
    }

    async getAccount() {
        let resp = await axios.get('account');
        return resp.data;
    }

    async getDevices(installation) {
        let resp = await axios.get('inst/'+installation+"/deviceaccess");
        return resp.data;
    }

    async getSensors(installation) {
        let resp = await axios.get('inst/'+installation+"/sensors");
        return resp.data;
    }

    async getGateways(installation) {
        let resp = await axios.get('inst/'+installation+"/gateways");
        return resp.data;
    }

    async grantDeviceAccess(installation, personname, daysvalid) {
        let req = {installationkey:installation,personname:personname,daysvalid:daysvalid};
        let resp = await axios.post('grantdeviceaccess', req);
        return resp.data;
    }

    async updateDeviceAccess(pkey, personname, daysvalid) {
        let req = {personname:personname,daysvalid:daysvalid};
        let resp = await axios.delete('deviceaccess/'+pkey, req);
        return resp.data;
    }
    
    async deleteDeviceAccess(pkey) {
        let resp = await axios.delete('deviceaccess/'+pkey);
        return resp.data;
    }

    async getCharts(installation) {
        let resp = await axios.get('inst/'+installation+"/charts");
        return resp.data;
    }

    async getSingleChart(installation, purposegroup) {
        let resp = await axios.get('inst/'+installation+"/chart/purpose/"+purposegroup);
        return resp.data;
    }

    async getFeeds(installation) {
        let resp = await axios.get('inst/'+installation+"/feed");
        return resp.data;
    }

    async getFeedHistory(feedpkey) {
        let resp = await axios.get('feed/'+feedpkey);
        return resp.data;
    }

    async seachFeeds(installationpkey, searchquery) {
        let req = {query:searchquery};
        let resp = await axios.post('inst/'+installationpkey+'/feed/search', req);
        return resp.data;
    }

    async sendIssueReaction(issuekey, reaction) {
        let resp = await axios.post('issue/'+issuekey, reaction);
        return resp.data;
    }

    async getClaimStatus(accesskey) {
        let resp = await axios.get('deviceaccess/status/'+accesskey);
        return resp.data;
    }

}


const restApi = new RESTService();

export function useRestApi() { return restApi; }