<template>
    <ol-map :loadTilesWhileAnimating="true" 
            :loadTilesWhileInteracting="true" >

        <ol-view id="view" ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection" />

        <MyTileLayer>
            <ol-source-osm />
        </MyTileLayer>

        <ol-vector-layer >
            <ol-source-vector :projection="projection" :features="sensorFeaturesOK"></ol-source-vector>
            <ol-style>
                <ol-style-icon :src="iconSensorOK" :scale="1"></ol-style-icon>
            </ol-style>
        </ol-vector-layer>

        <ol-vector-layer >
            <ol-source-vector :projection="projection" :features="sensorFeaturesNOK"></ol-source-vector>
            <ol-style>
                <ol-style-icon :src="iconSensorNOK" :scale="1"></ol-style-icon>
            </ol-style>
        </ol-vector-layer>

        <ol-interaction-select @select="featureSelected" :condition="selectCondition" :filter="selectInteactionFilter" :features="selectedFeatures">
            <ol-style>
                <ol-style-circle :radius="10">
                    <ol-style-fill color="rgba(67, 71, 86, 0.4)"></ol-style-fill>
                    <ol-style-stroke :color="'#36FE9A'" :width="2"></ol-style-stroke>
                </ol-style-circle>
            </ol-style>
        </ol-interaction-select>

        <ol-overlay v-if="displayInfobox" :position="infoboxPosition">
            <template v-slot="slotProps">
                <div class="overlay-content flex flex-auto flex-column sensor-list-item" @click="goToSelectedSensor()">
                    <div class="text-xs text-primary">{{addressText}}</div>
                    <div class="text-xl">Sensor: {{selectedSensor.id}}</div>
                    <div style="display:none">{{ slotProps.position }}</div>
                </div>
            </template>
        </ol-overlay>

    </ol-map>
</template>

<script>
import { ref, computed, watch, onMounted, inject } from  "vue";
//import {transform} from 'ol/proj';
import {fromLonLat} from 'ol/proj';
import { Collection } from "ol";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import MyTileLayer from './MyTileLayer.vue';
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import iconSensorOK from '@/assets/sensorok.png';
import iconSensorNOK from '@/assets/sensornok.png';

export default ({
    name: "SensorsMap",
    components: { MyTileLayer },
    setup() {
        let m_componentMounted = false;
        let m_componentInitialized = false;
        const store = useStore();
        const router = useRouter();

        const center = ref([1049331.134767886,6215020.94753701]);
        const projection = ref('EPSG:3857'); // use Spherical Mercator
        const zoom = ref(20);
        const rotation = ref(0);
        const view = ref(null);
        
        const sensorFeaturesOK = ref([]); // Array of the features for the sensor without Issues
        const sensorFeaturesNOK = ref([]); // Array of the features for the sensor without Issues

        const selectedSensor = ref(null);
        const infoboxPosition = ref([1049331.134767886,6215020.94753701]);
        
        const onGetMapState = () => {
            //console.log(JSON.stringify(view.value.getCenter()));
            zoom.value = view.value.getZoom();
            center.value = view.value.getCenter();
            rotation.value = view.value.getRotation();
        }
        
        watch(() => store.getters.isready, function () { initWhenReady(); });
        watch(() => store.getters.sensors, function () { displaySensors(); });
        watch(() => store.getters.myinstallation, function () { centerToInstallation(); displaySensors(); });

        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();
        });

        const initWhenReady = async () => {
            store.dispatch("requireSensors");

            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;

            centerToInstallation();
            displaySensors();
        }

        const centerToInstallation = () => {
            let coord = [store.getters.geolocation.lon, store.getters.geolocation.lat];
            console.log("Map centered to: " + JSON.stringify(store.getters.geolocation));
            center.value = fromLonLat(coord, projection.value);
        }

        const onSelectSensor = (sensor) => {
            if (sensor && sensor?.pkey > 0) {
                //console.log("Selected sensor: " +sensor.id);
                let coord = [sensor.geolocation.longitude, sensor.geolocation.latitude];
                infoboxPosition.value = fromLonLat(coord, projection.value);
                selectedSensor.value = sensor;
            }
            else {
                //console.log("Deselected sensor");
                selectedSensor.value = null;
            }
        }

        const goToSelectedSensor = () => {
            if (selectedSensor.value) {
                router.push({name:"sensor", params: {displaysensorkey: selectedSensor.value.pkey} });
            }
        }
        

        const addressText = computed(() => {
            if (selectedSensor.value) {
                return store.getters.getSensorAddressText(selectedSensor.value);
            }
            return "";
        });

        const displayInfobox = computed(() => {
            if (selectedSensor.value) {
                return true;
            }
            return false;
        });

        const displaySensors = () => {
            if (store.getters.sensorscount > 0) {
                
                let featArrOK = [];
                let featArrNOK = [];
                store.getters.sensorsForMap.forEach(sen => {
                    let f = new Feature({ geometry: new Point(fromLonLat([sen.geolocation.longitude, sen.geolocation.latitude]), projection.value), sensor: sen, name: sen.id });
                    if (sen.activefeedscount > 0) {
                        featArrNOK.push(f);
                    }
                    else {
                        featArrOK.push(f);
                    }
                });
                sensorFeaturesOK.value = featArrOK;
                sensorFeaturesNOK.value = featArrNOK;
            }
        }

        const selectedFeatures = ref(new Collection())
        const selectConditions = inject("ol-selectconditions");
        const selectCondition = selectConditions.click;

        function featureSelected(event) {
            /*modifyEnabled.value = false
            if (event.selected.length > 0) {
                modifyEnabled.value = true
            }
            */
            let sensor = null;
            selectedFeatures.value = event.target.getFeatures();
            //console.log(selectedFeatures.value);
            if (selectedFeatures.value.getLength() > 0) {
                //let sensorname = selectedFeatures.value?.array_[0]?.values_?.name ?? "";
                sensor = selectedFeatures.value?.array_[0]?.values_?.sensor ?? null;
            }
            onSelectSensor(sensor);
            //console.log("Selected features: " + JSON.stringify(selectedFeatures.value));
        }

        const selectInteactionFilter = (feature) => {
            //console.log("FILTER: " + JSON.stringify(feature));
            return (feature.values_.sensor != undefined && feature.values_.name != undefined);
        };

        return {
            center, projection, zoom, rotation, view, sensorFeaturesOK, sensorFeaturesNOK,
            onGetMapState, goToSelectedSensor,
            featureSelected, selectedFeatures, selectConditions, selectCondition, selectInteactionFilter,
            selectedSensor, displayInfobox, addressText, infoboxPosition, iconSensorOK, iconSensorNOK
        }
    },
})
</script>

<style scoped>


.overlay-content {
    background: #444757;
    box-shadow: 0 5px 10px rgb(2 2 2 / 20%);
}

</style>

