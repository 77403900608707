<template>
    <div>
        <div class="flex flex-auto flex-column">
            <div class="title"><span class="font-bold">{{issue.label}}</span> <span class="text-sm text-dimmed">{{timestampText}}</span></div>
            <div class="message two-lines-text">{{issue.message}}</div>
        </div>
    </div>
</template>

<script>
//import { computed, onMounted} from  "vue";
import { computed, onMounted} from  "vue";
import { timestampToString } from "../epcommon";
//import { useStore } from "vuex";

export default ({
    name: "IssueListItem",
    components: {  },
    props: {
        issue: {
            type: Object,
            default: () => { return {pkey:0,label:"",message:"",timestamp:""} }
        }
    },
    setup(props) {
        //const store = useStore();

        onMounted(() => {
        });

        const timestampText = computed(() => {
            return timestampToString(props.issue.timestamp);
        });

        return { timestampText }
    }
})
</script>

<style scoped>

</style>

