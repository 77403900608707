import { createRouter, createWebHistory } from "vue-router";

import Home from './components/DashPage';

const routes = [
  {
    path: '/',
    name: "home",
    component: Home
  },
  {
    path: '/login',
    name: "login",
    component: () => import('./components/LoginPage')
  },
  {
    path: '/users',
    name: "users",
    component: () => import('./components/UsersPage')
  },
  {
    path: '/analyse',
    name: "analyse",
    component: () => import('./components/Analyse')
  },
  {
    path: '/map',
    name: "map",
    component: () => import('./components/MapPage')
  },
  {
    path: '/issues',
    name: "issues",
    component: () => import('./components/IssuesPage')
  },
  {
    path: '/feed/:displayfeedkey',
    name: "feed",
    component: () => import('./components/IssuesPage'),
    props : (route) => {
      const displayfeedkey = Number.parseInt(route.params.displayfeedkey, 10)
      if (Number.isNaN(displayfeedkey) || displayfeedkey < 0) {
        return 0;
      }
      return { displayfeedkey };
    }
    //props: true
  },
  {
    path: '/sensor/:displaysensorkey',
    name: "sensor",
    component: () => import('./components/InstallationPage'),
    props : (route) => {
      const displaysensorkey = Number.parseInt(route.params.displaysensorkey, 10)
      if (Number.isNaN(displaysensorkey) || displaysensorkey < 0) {
        return 0;
      }
      return { displaysensorkey };
    }
  },
  {
    path: '/search/:query',
    name: "search",
    component: () => import('./components/SearchPage'),
    props : true
  },
  {
    path: '/installation',
    name: "installation",
    component: () => import('./components/InstallationPage')
  },
  {
    path: '/help',
    name: "help",
    component: () => import('./components/HelpPage')
  },
  {
    path: '/metadata',
    name: "metadata",
    component: () => import('./components/Analyse'),
    props: { purposegroup: "metadata" }
  },
  { path: '/404', name: 'NotFound', component: Home }, // just forward to the homepage
  { path: '/:catchAll(.*)', redirect: '/404' }
]


const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  mode: 'history',
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes: routes
})

export default router;
