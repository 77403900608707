<template>
    <div>
        <div class="grid grid-nogutter">
            <div class="col-fixed mr-2">
                <Avatar icon="pi pi-user" shape="circle" class="surface-b" /> 
            </div>
            <div class="col">
                <div><span class="font-bold">{{reaction.by.person}}</span> <span class="text-sm text-dimmed"> {{timestampText}}</span></div>
                <div class="font-italic">{{reaction.comment}}</div>
                <div class="text-sm" v-if="hasFeedback"><span class="font-bold">Feedback:</span> {{feedbackText}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted} from  "vue";
import { timestampToString } from "../epcommon";


export default ({
    name: "HistoryEntryReaction",
    components: {  },
    props: {
        reaction: {
            type: Object,
            default: () => { return {pkey:0,comment:"",by:{person:"",device:"",timestamp:""}} }
        }
    },
    setup(props) {

        onMounted(() => {
        });

        const timestampText = computed(() => {
            return timestampToString(props.reaction.by.timestamp);
        });

        const hasFeedback = computed(() => {
            return (feedbackText.value.length > 0);
        });

        const feedbackText = computed(() => {
            let correct = -1;
            let acted = -1;
            let impact = -1;
            if (props.reaction?.feedback?.correct !== undefined)
            {
                correct = (props.reaction?.feedback?.correct === true ? 1 : 0);
            }

            if (props.reaction?.feedback?.acted !== undefined)
            {
                acted = (props.reaction?.feedback?.acted === true ? 1 : 0);
            }

            impact = props.reaction?.feedback?.impact ?? -1;
            
            let sCorrect = "";
            if (correct >= 0) {
                sCorrect = (0 == correct ? "Die Meldung trifft nicht zu." : "Die Meldung trifft zu.");
            }

            let sActed = "";
            if (acted >= 0) {
                sActed = (0 == acted ? "Es wurden keine Maßnahmen ergiffen." : "Es wurden Maßnahmen ergriffen.");
            }

            let sImpact = "";
            if (impact >= 0) {
                sImpact = (0 == impact ? "Keine Auswirkung auf die Pflanzen." : "Geschätzte Auswirkung auf die Pflanzen " + impact + '%.');
            }

            let txt = sCorrect;
            if (txt.length > 0 && sActed.length > 0) txt += ' ';
            txt += sActed;
            if (txt.length > 0 && sImpact.length > 0) txt += ' ';
            txt += sImpact;
            return txt;
        });

        return { timestampText, hasFeedback, feedbackText };
    }
})
</script>

<style scoped>

</style>

