<template>
    <div>
        <div class="flex flex-auto flex-column">
            <div class="text-xs ">{{addressText}}</div>
            <div class="text-xl">Sensor: {{sensor.id}}</div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted} from  "vue";
import { useStore } from "vuex";

export default ({
    name: "SensorListItem",
    components: {  },
    props: {
        sensor: {
            type: Object,
            default: () => { return {pkey:0,id:"",activefeedscount:0,gatewayid:"",assignment:3,status:0,plantid:0,geolocation:"",comment:"",address:[""]} }
        }
    },
    setup(props) {
        const store = useStore();

        onMounted(() => {
        });

        const addressText = computed(() => {
            return store.getters.getSensorAddressText(props.sensor);
            /*
            let dimensions = store.getters.installationaddressing;
            let sTxt = "";
            for (let i = 0; i < props.sensor.address.length; ++i) {
                let sItem = "";
                if (props.sensor.address[i].length > 0) {
                    if (i < dimensions.length)
                    {
                        let label = dimensions[i].label ?? "";
                        if (label.length > 0) {
                            sItem += label + " ";
                        }
                    }
                    sItem += props.sensor.address[i];
                    if (sTxt.length > 0) sTxt += ', ';
                    sTxt += sItem;
                }
            }
            return sTxt;
            */
        });

        return { addressText }
    }
})
</script>

<style scoped>

</style>

