<template>
    <Button :class="buttonClass" @click="onButtonClicked" />
</template>

<script>
//import { ref /*, computed, onMounted */ } from "vue";

export default {
  name: "FilterButton",
  components: {  },
  props: ['selected', 'selectionoption'],
  emits: ['filterSelected', 'filterDeselected'],
  data ()
  {
      return {
          isSelected: this.selected,
          option: this.selectionoption
      };
  },
  methods: {
      onButtonClicked() {
          this.isSelected = !this.isSelected;
          if (this.isSelected) this.$emit('filterSelected', this.option);
          else this.$emit('filterDeselected', this.option);
      }
  },
  computed:{
      buttonClass () {
          return this.isSelected ? "selected-filter" : "nonselected-filter";
      }
  }
};

</script>

<style scoped>

</style>
