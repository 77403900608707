

export function handleErrorReponseWithToast(error, toast)
{
    if (error.response === undefined) return;

    if (error.response.status == 401) return;

    if (error.response.data !== undefined && error.response.data.error !== undefined) {
        toast.add({severity: "error", summary: "Abfrage fehgeschlagen", detail: error.response.data.error, life: 15000 });
    }
    else {
        toast.add({severity: "error",  summary: "Die Abfrage ist fehgeschlagen",
                    detail: "Ein Fehler ist Aufgetreten. Der Server meldet status " + error.response.status, life: 15000 });
    }
}


export function processErrorResponseWithToast(error, store)
{
    console.log("processErrorResponseWithToast: " + JSON.stringify(error));
    
    if (error.response === undefined) {
        store.commit("seterror", {caption : "Generic error", message: error?.message ?? "", duration: 10000} );
        return;
    }

    if (error.response?.status == 401) {
        return; // ignore 401 Unauthorized
    }

    if (error.response?.data?.error !== undefined) {
        store.commit("seterror", {caption: "Die Abfrage ist fehlgeschlagen", 
                    message: error.response?.data?.error, duration: 15000} );
    }
    else {
        store.commit("seterror", {caption: "Die Abfrage ist fehlgeschlagen", 
                    message: "Ein Fehler ist Aufgetreten. Der Server meldet status " + error.response?.status, duration: 15000} );
    }
}

const today = new Date();
let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const dateoptionsSameMonth = { weekday: 'short', month: 'long', day: '2-digit' };
const dateoptionsSameYear = { month: 'long', day: '2-digit' };
const dateoptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
const timeoptions = { timeStyle: 'short' };


const isSameDay = (d1, d2) => {
    return d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
}

const isSameMonth = (d1, d2) => {
    return d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
}

const isSameYear = (d1, d2) => {
    return d1.getFullYear() == d2.getFullYear();
}

export function timestampToString(tstamp, bVom=false)
{
    if (tstamp.length > 0)
    {
        let t = new Date(tstamp);
        // if the date is not we are probably dealing with safari
        if (isNaN(t)) {
            t = new Date(tstamp.replace(/-/g, "/")); // safari doesn't like dashes in dates
        }
        // TODO - consider using Date.parse
        
        if (isSameDay(t, today)) {
            return (bVom ? "von " : "") + "heute um " + t.toLocaleTimeString("de-DE", timeoptions);
        } else if (isSameDay(t, yesterday)) {
            return (bVom ? "von " : "") + "gestern um " + t.toLocaleTimeString("de-DE", timeoptions);
        } else {
            if (isSameMonth(t, today))
            {
                return (bVom ? "vom " : "am ") + t.toLocaleDateString("de-DE", dateoptionsSameMonth) + " um " + t.toLocaleTimeString("de-DE", timeoptions);
            } else if (isSameYear(t, today))
            {
                return (bVom ? "vom " : "am ")  + t.toLocaleDateString("de-DE", dateoptionsSameYear) + " um " + t.toLocaleTimeString("de-DE", timeoptions);
            }
            return (bVom ? "vom " : "am ") + t.toLocaleDateString("de-DE", dateoptions) + " um " + t.toLocaleTimeString("de-DE", timeoptions);
        }
    }
    return "";
}
