<template>
    <div class="relative" ref="rootnode" :class="{'active-search' : isSearchActive, 'non-active-search' : !isSearchActive}" >
        <span class="p-input-icon-left z-2">
            <i class="pi pi-search searchicon"></i>
            <InputText maxlength="45" class="w-30rem searchinput" id="idInputSearchText" placeholder="Suchen..." 
                v-model="searchText"
                @keyup.enter="submitSearch()"
                @keyup.down="focusSuggestionsList()"
                @keyup.esc="focusedInput=false"
                 />
        </span>
        <div v-if="isSearchActive" id="idSuggestionslist" tabindex="1" 
            class="absolute z-1 top-auto left-0 w-full">
            <div v-if="isLastSearchVisible" class="last-search-box">
                <div class="mb-2"> Letzte Suchvorgänge:</div>
                <div >
                 <Chip v-for="(t, index) in lastSearchTerms" 
                        :key="index" 
                        :label="t"
                        class="last-search-term-chip"
                        @click="searchText = t" />
                </div>
            </div>
            <div v-else-if="isSuggestionslistVisible" >
                <Listbox class="suggestions-box"
                        @keyup.esc="focusedInput=false"
                        tabindex="2" ref="listboxsuggestions"
                        v-model="selectedSuggestion" 
                        :options="filteredSuggestions" 
                        optionLabel="label" 
                        
                        optionGroupLabel="label" 
                        optionGroupChildren="items" 
                        listStyle="max-height:200px" >
                        
                        <template #optiongroup="slotProps">
                            <div class="p-d-flex p-ai-center country-item">
                                <div>{{slotProps.option.label}}</div>
                            </div>
                        </template>
                </Listbox>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeUnmount, watch , computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "GlobalSearchInput",
  components: {  },
  setup ()
  {
    const router = useRouter();
    const store = useStore();
    const rootnode = ref(null);
    const listboxsuggestions = ref(null);
    const searchText = ref('');
    const filteredSuggestions = ref([]);
    const selectedSuggestion = ref();
    const focusedInput = ref(false);
    let timeoutIdClose = null;

    
    onMounted(() => {
        document.addEventListener('focusin', focusChanged);
        document.addEventListener('focusout', focusLost);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('focusin', focusChanged);
        document.removeEventListener('focusout', focusLost);
    });

    watch(searchText, () => { onSearchTextChanged(); } );
    
    watch(selectedSuggestion, () => {
        if (selectedSuggestion.value) {
            if (selectedSuggestion.value.feed) {
                // navigate to feed
                let feed = selectedSuggestion.value.feed;
                store.commit("addsearchterm", "Meldung #" + feed.pkey + " " + feed.label);
                router.push({name:"feed", params: {displayfeedkey: feed.pkey} });
                clearAfterSubmit();
            }
            else if (selectedSuggestion.value.sensor) {
                // navigate to sensor
                let sensor = selectedSuggestion.value.sensor;
                store.commit("addsearchterm", "Sensor " + sensor.id);
                router.push({name:"sensor", params: {displaysensorkey: sensor.pkey} });
                clearAfterSubmit();
            }
            else {
                submitSearch();
            }
        }
    } );

    const isSearchActive = computed(() => {
        return (true === focusedInput.value)
    });

    const isLastSearchVisible = computed(() => {
        return (true === focusedInput.value && 
                0 == searchText.value.length &&
                store.getters.lastsearchterms.length > 0);
    });

    const isSuggestionslistVisible = computed(() => {
        return (true === focusedInput.value && searchText.value.length > 0 && filteredSuggestions.value.length > 0);
    });

    const lastSearchTerms = computed(() => {
        return store.getters.lastsearchterms;
    });

    const submitSearch = () => {
        if (searchText.value.length > 0) {
            store.dispatch("search", searchText.value);
        }
        clearAfterSubmit();
    }

    const clearAfterSubmit = () => {
        focusedInput.value = false;
        searchText.value = ""; // clear search text
        selectedSuggestion.value = null; // clear selection
    }

    const isInsideRoot = (el) => {
        if (null === el) return false;

        if (rootnode.value) {
            return (rootnode.value.contains(el));
        }
        return false;
    }

    const cancelCloseTimer = () => {
        if (timeoutIdClose) {
            clearTimeout(timeoutIdClose);
        }
        timeoutIdClose = null;
    }

    const startCloseTimer = () => {
        cancelCloseTimer();
        if (focusedInput.value) {
            timeoutIdClose = setTimeout(() => { 
                focusedInput.value = false;
            }, 100);
        }
    }

    const focusChanged = (event) => {
        if (event.target?.id === "idInputSearchText") {
            //console.log("Clicked inside input");
            cancelCloseTimer();
            focusedInput.value = true;
        }
    };

    const focusLost = (event) => {
        if (!isInsideRoot(event.relatedTarget)) {
            //console.log("Close the suggestions");
            startCloseTimer();
        }
    };

    const onSearchTextChanged = async () => {
        if (searchText.value.length > 0) {
            focusedInput.value = true;

            let g = {label: "Alles durchsuchen nach", items:[ {label: searchText.value} ]};
            let arr = [g]
            
            let sensors = store.getters.lookupSensorsMatchingText(searchText.value);
            if (sensors.length > 0)
            {
                g = {label: "Sensoren", items : sensors.map(x => {
                        return {label: x.id, sensor: x};
                    })
                };
                arr.push(g);
            }

            let feeds = store.getters.lookupFeedMatchingText(searchText.value);
            if (feeds.length > 0)
            {
                g = {label: "Meldungen", items : feeds.map(x => {
                        return {label: x.label, feed: x};
                    })
                };
                arr.push(g);
            }

            filteredSuggestions.value = arr;
        }
        else {
            filteredSuggestions.value = [];
        }
    }
    
    const focusSuggestionsList = () => {
        if (isSuggestionslistVisible.value)
        {
            let firstListItem = listboxsuggestions.value.$el.querySelector("li");
            if (firstListItem) {
                firstListItem = firstListItem.nextElementSibling ?? null;
                if (firstListItem) {
                    firstListItem.focus();
                }
            } 
        }
    }

    return { rootnode, listboxsuggestions,
            isSearchActive, isLastSearchVisible, isSuggestionslistVisible,
            focusedInput, lastSearchTerms,
            searchText, submitSearch, filteredSuggestions, onSearchTextChanged,
            selectedSuggestion,
            focusSuggestionsList
            };
  }
};

</script>

<style scoped>

.active-search {
    color: var(--primary-color-text);
}

.searchinput {
    background-color: transparent;
    color: var(--primary-color);
}

.searchinput:focus {
    background-color: var(--primary-color);
    color: var(--text-color-primary-inverted);
}

.active-search .searchicon {
    color: var(--text-color-primary-inverted);
}

.last-search-box
{
    padding: 1rem;
    padding-bottom: 3em;
    background: linear-gradient(var(--primary-color), var(--surface-ground));
}

.suggestions-box
{
    background: linear-gradient(var(--primary-color), var(--surface-b));
}

.last-search-term-chip {
    margin-left: 0.5rem;
    margin-right: 0.5rem; 
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem; 
    background: var(--surface-e);
    color: var(--text-color);
    cursor: pointer;
}

</style>
