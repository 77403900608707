<template>
    <div class="content-area" >
        <h2>Kultur</h2>
        
        <div class="flex flex-none align-self-end mx-2 mb-2">
            <span class="p-input-icon-left">
            <i class="pi pi-filter" />
            <InputText type="text" class="p-inputtext-sm" v-model="m_sSearchText" placeholder="Filtern" @input="showAgricultures()" />
            </span>
        </div>

        <div class="scrollable-flex">
            <div class="surface-100 shadow-2 p-3 ml-0 mr-2 mb-4 cursor-pointer border-round "
                v-for="(a) in m_Agricultures" :key="a.pkey"
                @click="onClickAgriculture(a)"
            >{{a.label}}</div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted} from  "vue";
import { useStore } from "vuex";
import InputText from "primevue/inputtext";

export default ({
    name: "Kulturenliste",
    components: { InputText },
    props: {
        selectedagriculturekey: {
            type: Number,
            default: 0
        }
    },
    emits: [ "update:selectedagriculturekey"],
    setup(props, context) {
        
        const store = useStore();
        const m_Agricultures = ref([]);
        const m_sSearchText = ref("");
        let m_componentMounted = false;
        let m_componentInitialized = false;
        
        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });

        const initWhenReady = async () => {
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;
            
            showAgricultures();
        }

        const matchesSearch = (agriculture) =>
        {
            let txt = m_sSearchText.value.toLowerCase();
            return (agriculture.label.toLowerCase().includes(txt));
        }

        const showAgricultures = () => {
            if (m_sSearchText.value.length > 0) {
                m_Agricultures.value = store.getters.agricultures.filter(matchesSearch);
            }
            else {
                m_Agricultures.value = store.getters.agricultures;
            }
            
        };

        const onClickAgriculture = (a) => {
            console.log("Clicked agriculture " + a.label);
            //props.selectedagriculturekey.value = a.pkey;
            context.emit('update:selectedagriculturekey', a.pkey);
        }

        return { m_sSearchText, m_Agricultures, showAgricultures, onClickAgriculture };
    },
})
</script>

<style scoped>


</style>

