<template>
    <Login v-if="$route.path === '/login'" />
    <App v-else />
</template>

<script>
    import App from '../App.vue';
    import Login from './LoginPage';
    import store from "../store"

	export default {
        components: {
            'App': App,
            'Login': Login
        },
        beforeCreate() {
            this.$store.watch(
                function(state) { return state.currentInstallation; }, 
                function() {
                    store.dispatch("refreshFeeds");
                    store.dispatch("refreshSensors");
                    store.dispatch("refreshCharts");
                    store.dispatch("refreshGateways");
                }
            );
            this.$store.commit("initializeStore");
        }
    }

</script>

<style scoped>
</style>
