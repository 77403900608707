<template>
    <div class="" style="height:200px">

        <lottie-animation class=""
            ref="anim"
            path="livestatus.json"
            :loop="true"
            :autoPlay="true"
        />

        <!--<LottieAnimation
            ref="anim"
            :animationData="require('../assets/livestatus.json')"
            :loop="true"
            :autoPlay="true"
            @loopComplete="loopComplete"
            @complete="complete"
            @enterFrame="enterFrame"
        />-->

    </div>
</template>

<script>
import { ref, onMounted} from  "vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
// https://www.npmjs.com/package/lottie-vuejs

export default ({
    name: "LiveStatus",
    components: { LottieAnimation },
    setup() {
        const anim = ref(null);

        const loopComplete = () => {
            console.log('loopComplete');
        }

        const complete = () => {
            console.log('complete');
        }

        const enterFrame = () => {
            console.log('enterFrame');
        }

        onMounted(() => {
            console.log("Animation mounted")
            // the DOM element will be assigned to the ref after initial render
            //anim.value.play();
        })

        return {
            anim, 
            loopComplete,
            complete,
            enterFrame
        }
    }
})
</script>

<style scoped>

</style>

