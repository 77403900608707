<template>
    <div>
        <Chart v-if="chartAvailable" :type="chartType" :data="chartData" :options="chartOptions" />
    </div>
</template>

<script>
import { ref, onMounted, computed, watch} from  "vue";
import Chart from 'primevue/chart';
import { useToast } from "primevue/usetoast"
//import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useRestApi } from "../RESTService";
import { handleErrorReponseWithToast } from "../epcommon";


export default ({
    name: "ChartWidget",
    components: { Chart },
    props: {
        purposegroup: {
            type: String,
            default: "analyse",
            validator(value) {
                return ['analyse', 'metadata'].includes(value); // The value must match one of these strings
            }
        }
    },
    setup(props) {
        const store = useStore();
        const toast = useToast();
        const restService = useRestApi();
        const m_Chart = ref(null);
        let m_componentMounted = false;
        let m_componentInitialized = false;
        
        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });
        
        watch(() => store.getters.myinstallation, function () { 
            if (store.getters.isready) {
                console.log("REINIT chart " + props.purposegroup);
                m_Chart.value = null;
                initChart();
            }
        });

        const initWhenReady = async () => {
            //console.log("called initWhenReady: initialized=" + m_componentInitialized + ", mounted=" + m_componentMounted + ", ready="+store.getters.isready);
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;
            initChart();
        }

        const initChart = async () => {
            restService.getSingleChart(store.state.currentInstallation, props.purposegroup).then((response) => {
                if (response.length > 0)
                {
                    m_Chart.value = response[0];
                }
            }).catch((error) => handleErrorReponseWithToast(error, toast));
        }

        const chartAvailable = computed( () => { return (m_Chart.value != null); } );
        const chartType = computed( () => { if (m_Chart.value) { return m_Chart.value.type; } else return "line"; } );
        const chartData = computed( () => { if (m_Chart.value) { return m_Chart.value.data; } else return {}; } );
        const chartOptions = computed( () => { if (m_Chart.value) { return m_Chart.value.options; } else return {}; } );

        return { chartAvailable, chartType, chartData, chartOptions };
    },
})
</script>

<style scoped>

</style>

