<template>
  <div :class="layoutContainerClass" @click="onDocumentClick">
			<div v-if="renderComponent" class="layout-main">
        <AppMenu :model="menu" :menuMode="menuMode" 
                :menuActive="menuActive" 
                :sidebarActive="sidebarActive" 
                :sidebarStatic="sidebarStatic" 
                :pinActive="pinActive" 
                :activeInlineProfile="activeInlineProfile"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu" 
                @change-inlinemenu="onChangeActiveInlineMenu" 
                @menu-click="onMenuClick" 
                @menuitem-click="onMenuItemClick" 
                @root-menuitem-click="onRootMenuItemClick" />
        
        <div class="layout-breadcrumb-container">
          <div class="layout-breadcrumb-left-items pl-4">
            E-Plant Installation:
            <Dropdown class="installation-choice-dropdown" v-model="selectedInstallationPkey" :options="installations"
                        optionLabel="alias" optionValue="pkey" @change="onInstallationChange" />

          </div>

          <div class="layout-breadcrumb-right-items">
            <div >
              <GlobalSearchInput />
            </div>

            <div class="ml-4">
              <Button icon="pi pi-bell" class="p-button-rounded" @click="toggleProfileMenu" >{{userInitials}}</Button>
              <Menu ref="profilemenu" :model="profileMenuItems" :popup="true" />
            </div>
          </div>

        </div>

        <div class="flex flex-auto flex-column px-4 pt-4 mb-4" style="align-content: stretch;">
          <router-view />
        </div>
      </div>
    <Toast />
  </div>
</template>

<script>
import Toast from "primevue/toast";
import Button from "primevue/button";
import Menu from 'primevue/menu';
import EventBus from './components/event-bus';
import AppMenu from './components/AppMenu';
import GlobalSearchInput from './components/GlobalSearchInput.vue';

export default {
  name: "App",
  emits: ['layout-mode-change', 'layoutModeChange', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'change-color-scheme', 'change-inlinemenu'],
  components: {
    Toast, AppMenu, Button, Menu, GlobalSearchInput
  },
  beforeMount() {
    this.sidebarStatic = this.$store.getters.ismenupinned;
    this.sidebarActive = this.sidebarStatic;
  },
  data() {
    return {
      menuMode: "sidebar",
      menuActive: false,
      activeInlineProfile: false,
      inlineMenuClick: false,
      overlayMenuActive: false,
      topbarMenuActive: false,
      topbarItemClick: false,
      sidebarActive: true,
      sidebarStatic: true,
      pinActive: true,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rightMenuClick: false,
      rightMenuActive: false,
      configActive: false,
      configClick: false,
      preventMouseOver: false,
      renderComponent: true,
      selectedInstallationPkey: 0,
      
      profileMenuItems: [
        {
          // TODO - continue here - 
          label: "Profil",
          to: "/users",
        },
        {
          label: "Hilfe",
          to: "/help",
        },
        {
          separator:true
        },
        {
          label: "Abmelden",
          command: () => { 
            this.$store.dispatch("logout");
          }
        }
      ],

      menu: [
        {
          label: "Dashboard",
          icon: "space_dashboard",
          to: "/",
        },
        {
          label: "Meldungen",
          icon: "chat",
          to: "/issues"
        },
        {
          label: "Installation",
          icon: "memory",
          to: "/installation"
        },
        {
          label: "Karte",
          icon: "location_on",
          to: "/map"
        },
        {
          label: "Analyse",
          icon: "trending_up",
          to: "analyse"
        },
        {
          label: "Metadaten",
          icon: "exit_to_app",
          to: "/metadata"
        },
        {
          label: "Benutzer",
          icon: "person",
          to : "/users"
        },
        {
          label: "Shop",
          icon: "shopping_cart",
          to: "/shop"
        },
        {
          label: "Community",
          icon: "forum",
          command: () =>
            window.open("https://eplant.info", "_blank")
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
    currentinstallation() {
      console.log("Current INSTA changed in store");
      this.selectedInstallationPkey = this.$store.getters.myinstallation?.pkey ?? 0;
    },
    lasterror(e)
    {
        if (e.valid)
        {
            this.$toast.add({severity: "error", 
                    summary: e.caption,
                    detail: e.message,
                    life: e.duration });
        }
    }
  },
  methods: {
    toggleProfileMenu(event) {
        this.$refs.profilemenu.toggle(event);
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onInstallationChange() {
      //console.log("Invoke installation change: " + this.selectedInstallationPkey);
      this.$store.dispatch("changeinstallation", this.selectedInstallationPkey);
    },
    onDocumentClick() {

      if (!this.topbarItemClick) {
        this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false;
          EventBus.emit("reset-active-index");
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.overlayMenuActive = false;
          this.staticMenuMobileActive = false;
        }

        this.hideOverlayMenu();
        this.unblockBodyScroll();
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.topbarItemClick = false;
      this.menuClick = false;
      this.configClick = false;
      this.rightMenuClick = false;
      this.inlineMenuClick = false;
    },
    onSidebarMouseOver() {
      if (this.preventMouseOver) return;

      if (this.menuMode === "sidebar" && !this.sidebarStatic) {
        this.sidebarActive = this.isDesktop();
        setTimeout(() => {
          this.pinActive = this.isDesktop();
        }, 200);
      }
    },
    onSidebarMouseLeave() {
      if (this.menuMode === "sidebar" && !this.sidebarStatic) {
        setTimeout(() => {
          this.sidebarActive = false;
          this.pinActive = false;
          this.preventMouseOver = false;
        }, 250);
      }
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }

      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onRightMenuButtonClick() {
      this.rightMenuClick = true;
      this.rightMenuActive = true;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        this.hideOverlayMenu();
        EventBus.emit("reset-active-index");
      }

      if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
        this.menuActive = false;
      }
    },
    onChangeMenuMode(menuMode) {
      this.menuMode = menuMode;
      this.overlayMenuActive = false;
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick(event) {
      this.configActive = !this.configActive;
      this.configClick = true;
      event.preventDefault();
    },
    onChangeRightMenuActive(active) {
      this.rightMenuActive = active;
    },
    onToggleMenu(event) {
      this.menuClick = true;

      if (this.overlayMenuActive) {
        this.overlayMenuActive = false;
      }

      if (this.sidebarActive) {
        this.sidebarStatic = !this.sidebarStatic;

        if (!this.sidebarStatic)  {
          this.preventMouseOver = true;
          this.sidebarActive = false;
          this.pinActive = false;
          setTimeout(() => {
            this.preventMouseOver = false;
          }, 350);
        }
      }

      setTimeout(() => {
        this.forceRerender();
      }, 400);

      this.$store.commit("setmenupinned", this.sidebarStatic);
      event.preventDefault();
    },
    onChangeActiveInlineMenu() {
      this.activeInlineProfile = !this.activeInlineProfile;
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp(
            "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isHorizontal() {
      return this.menuMode === "horizontal";
    },
    isSlim() {
      return this.menuMode === "slim";
    },
    isOverlay() {
      return this.menuMode === "overlay";
    },
    isStatic() {
      return this.menuMode === "static";
    },
    isSidebar() {
      return this.menuMode === "sidebar";
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
    isMobile() {
      return window.innerWidth <= 991;
    },
  },
  computed: {
    lasterror() {
      return this.$store.getters.lasterror;
    },
    userInitials() {
      return this.$store.getters.account.initials;
    },
    installations() {
      return this.$store.getters.installations;
    },
    currentinstallation() {
      return this.$store.getters.myinstallation;
    },
    installationAlias() {
      return this.$store.getters.myinstallation?.alias ?? "";
    },
    layoutContainerClass() {
      let containercl = [
        "layout-wrapper",
        {
          "layout-static": this.menuMode === "static",
          "layout-overlay": this.menuMode === "overlay",
          "layout-overlay-active": this.overlayMenuActive,
          "layout-slim": this.menuMode === "slim",
          "layout-horizontal": this.menuMode === "horizontal",
          "layout-active": this.menuActive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-sidebar": this.menuMode === "sidebar",
          "layout-sidebar-static":
            this.menuMode === "sidebar" && this.sidebarStatic,
          "layout-static-inactive":
            this.staticMenuDesktopInactive && this.menuMode === "static",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
      return containercl;
    },
  },
};
</script>

<style lang="scss">

.installation-choice-dropdown {
  border: 0px none transparent;
}

</style>
