import { createStore } from 'vuex'
import {useRestApi} from "../RESTService";
import router from '../routes';
import { processErrorResponseWithToast } from "../epcommon";

/*
export default new Vuex.Store({
  modules: {
    cart,
    products
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})*/

// Create a new store instance.
const store = createStore({
    state () {
      return {
        account: null,
        refreshTask: null,
        currentInstallation: 0,
        installations: [],
        installation: null,
        tokens : null,
        lasterr : null,
        feeds : [],
        charts : [],
        sensors : [],
        gateways: [],
        lastsearchterms: [], // a list of strings searched last
        search: { searchrunning: false, searchquery: '', foundfeeds: [], foundfeedsinhistory: [], foundsensors: [] },
        devices: [], // a list of device access items
        ismenupinned: true
        
        //plantgroupedsensors: []  // a list of object where only deployed sensors are grouped by plantid {plantid:String, sensors:Array}
      }
    },
    getters: {
      accesstoken(state) {
        return state.tokens?.accesstoken ?? "";
      },
      refreshtoken(state) {
        return state.tokens?.refreshtoken ?? "";
      },
      isloggedin(state) {
        let a = state.tokens?.accesstoken ?? "";
        let b = state.tokens?.refreshtoken ?? "";
        return (a.length > 0 && b.length > 0);
      },
      isready(state, getters) {
        if (getters.isloggedin && state.installations.length > 0 && state.currentInstallation > 0 && state.installation != null) {
          return (state.tokens.accesstoken.length > 0 && state.tokens.refreshtoken.length > 0);
        }
        return false;
      },
      installations(state) {
        return state.installations;
      },
      myinstallation(state) {
        return state.installation;
      },
      installationpkey(state) {
        return state.installation?.pkey ?? 0;
      },
      geolocation(state) { // returns the geo-location of the installation as an object {lat:X, lon:Y}
        let glocstr = state.installation?.geolocation ?? "";
        let coord = {lat:0,lon:0};
        if (glocstr.length > 0) {
          const words = glocstr.split(',');
          if (words.length >= 2) {
            coord.lat = parseFloat(words[0]);
            coord.lon = parseFloat(words[1]);
          }
        }
        return coord;
      },
      installationlastmodepkey(state) {
        return state.currentInstallation;
      },
      account(state) {
        let o = { valid:false, username:"", firstname:"", lastname:"", initials:""};
        if (state.account)
        {
          o.username = state.account?.username ?? "";
          o.firstname = state.account?.firstname ?? "";
          o.lastname = state.account?.lastname ?? "";

          o.valid = (o.firstname.length > 0 && o.lastname.length > 0);
          if (o.valid) {
            o.initials = o.firstname.charAt(0).toLocaleUpperCase() + o.lastname.charAt(0).toLocaleUpperCase();
          }
        }
        return o;
      },
      lasterror(state) {
        let o = { valid:false, caption:"", message:"", duration:0 };
        if (state.lasterr) {
          o.caption = state.lasterr?.caption ?? "";
          o.message = state.lasterr?.message ?? "";
          o.duration = state.lasterr?.duration ?? 0;
          o.valid = (o.caption.length > 0 || o.message.length > 0);
          if (o.valid && o.duration < 1000) o.duration = 1000;
        }
        return o;
      },
      lastsearchterms(state) {
        return state.lastsearchterms;
      },
      feedscount(state) {
        return state.feeds?.length;
      },
      feeds(state) {
        return state.feeds;
      },
      charts(state) {
        return state.charts;
      },
      gateways(state) {
        return state.gateways;
      },
      agricultures(state, getters) {
        if (getters.isready) {
          return state?.installation?.agricultures ?? [];
        }
        else {
          return [];
        }
      },
      getFeedsOfSensor: (state) => (sensorkey) => {
        return state.feeds.filter(f => (sensorkey === (f.sensorkey ?? 0)) );
      },
      getAgricultureNameByPkey: (state) => (agriculturepkey) => {
        let agris = state.installation?.agricultures ?? [];
        if (agris.length > 0)
        {
          let found = agris.find(a => a.pkey === agriculturepkey);
          if (found) return found?.label ?? "";
        }
        return "";
      },
      sensors(state, getters)
      {
        if (getters.isready) {
          return state?.sensors ?? [];
        }
        else {
          return [];
        }
      },
      sensorscount(state) {
        return state.sensors?.length;
      },
      sensorsForMap(state) {
        // returns an array of only deployed sensors with a valid geolocation
        return state.sensors.filter(sen => {
          if (1 == sen.assignment) {
            if (sen.geolocation?.latitude && sen.geolocation?.longitude)
            {
              return true;
            }
          }

          return false;
        });
      },
      devices(state) {
        return state.devices;
      },
      installationaddressing(state)
      {
        return state.installation?.config?.sensor_addressing ?? [];
      },
      findFeedByPkey: (state) => (feedpkey) => {
        if (feedpkey === undefined || feedpkey <= 0) return null;
        let feeds = state?.feeds ?? [];
        return feeds.find(el => el.pkey == feedpkey) ?? null;
      },
      lookupSensorsMatchingText: (state) => (txt, bMatchIdOnly = false) => {
        if (txt === undefined || txt.length == 0) return [];
        let sensors = state?.sensors ?? [];
        let txtOriginal = txt;
        txt = txt.toLowerCase().trim();

        let sensorTxt = txt;
        if (sensorTxt.startsWith('sensor')) sensorTxt = sensorTxt.slice(6).trim();
        if (sensorTxt.length == 0) return [];

        let addressTxt = txt.replaceAll(/\s/g,''); // remove all whitespace characters from the string

        //console.log("Search for " + txt + ", sensorTxt=" +sensorTxt+", addressTxt="+addressTxt);

        return sensors.filter(el => {
            if (el.id.toLowerCase().includes(sensorTxt) ) return true;
            if (!bMatchIdOnly) {

              if (txt.length >= 3) { // don't search comments and plants for short search patterns
                if (el.comment) if (el.comment.toLowerCase().includes(txt) ) return true;
                if (el.plantid) if (el.plantid.toLowerCase().includes(txt) ) return true;
              }
              
              // check if anything from the address matches
              //let lowercasedAddress = el.address.map(a => a.toLowerCase());
              if (el.address.includes(txtOriginal) ) return true;

              //console.log("Sensor: " + el.pkey + ": " + el.address.join('').toLowerCase());
              if (el.address.join('').toLowerCase() == addressTxt) return true;

              // TODO - match agriculture
              // TODO - consider matching the gateway as well
              
            }
          return false;
        });
      },
      lookupFeedMatchingText: (state, getters) => (txt) => {
        if (txt === undefined || txt.length == 0) return [];
        let feeds = state?.feeds ?? [];
        let sensors = getters.lookupSensorsMatchingText(txt, true); // match only sensor ids
        txt = txt.toLowerCase().trim();
        let feedpkey = 0;
        if (txt[0] == '#' || txt.startsWith('meldung')) {
          if (txt[0] != '#') txt = txt.slice(7).trim();
          let matches = txt.matchAll(/#(\d{1,10})/g);
          for (const match of matches) {
            if (match.length > 1) {
              feedpkey = parseInt(match[1]);
              break;
            }
          }
        }

        return feeds.filter(el => {
          if (feedpkey > 0) {
            return (el.pkey == feedpkey);
          }

          if (txt.length >= 3) { // search label and message if we have at least 3 characters
            if (el.label.toLowerCase().includes(txt) ) return true;
            if (el.message.toLowerCase().includes(txt) ) return true;
          }
          
          if (el.sensorkey) {
            let foundSensor = sensors.find(sen => {sen.pkey === el.sensorkey} );
            if (foundSensor) return true;
          }

          return false;
        });
      },
      findSensorByPkey: (state) => (sensorpkey) => {
        if (sensorpkey === undefined || sensorpkey <= 0) return null;
        let sensors = state?.sensors ?? [];
        return sensors.find(el => el.pkey == sensorpkey) ?? null;
      },
      findGatewayByPkey: (state) => (gatewaywpkey) => {
        if (gatewaywpkey === undefined || gatewaywpkey <= 0) return null;
        let gwarr = state?.gateways ?? [];
        return gwarr.find(el => el.pkey == gatewaywpkey) ?? null;
      },
      getSensorAddressText: (state) => (sensor, bIncludePlantId = true) => {
        if (sensor === undefined || !sensor) return "";

        let dimensions = state.installation?.config?.sensor_addressing ?? [];
        let sTxt = "";
        for (let i = 0; i < sensor.address.length; ++i) {
            let sItem = "";
            if (sensor.address[i].length > 0) {
                if (i < dimensions.length)
                {
                    let label = dimensions[i].label ?? "";
                    if (label.length > 0) {
                        sItem += label + " ";
                    }
                }
                sItem += sensor.address[i];
                if (sTxt.length > 0) sTxt += ', ';
                sTxt += sItem;
            }
        }

        if (bIncludePlantId) {
          let plantid = sensor.plantid ?? "";
          if (plantid.length > 0)
          {
            if (sTxt.length > 0) sTxt += ', ';
            sTxt += plantid;
          }
        }

        return sTxt;
      },
      getChartsByPurposegroup: (state) => (purposegroup) => {
        return state.charts.filter(c => c.purposegroup === purposegroup);
      },
      getChartsBySensorID: (state) => (sensorid) => {
        return state.charts.filter(c => (c?.sensorid || '') === sensorid);
      },
      search(state) {
        return state.search;
      },
      ismenupinned(state) {
        return state.ismenupinned;
      }
    },
    mutations: {
      initializeStore(state) 
      {
        console.log("Store init!");

        {
          let v = localStorage.getItem('currentInstallation');
          if (v) {
            state.currentInstallation = parseInt(v);
          }
        }
        
        {
          let v = localStorage.getItem('ismenupinned');
          if (v) {
            state.ismenupinned = (v === 'true');
          }
        }
        
        let storedtokens = null;
        try {
          let storedTokensStr = localStorage.getItem('tokens');
          if (storedTokensStr?.length > 0)
          {
            storedtokens = JSON.parse(storedTokensStr);
          }

          let lst = localStorage.getItem('lastsearchterms');
          if (lst?.length > 0)
          {
            let arr = JSON.parse(lst);
            if (Array.isArray(arr)) {
              if (arr.every(i => (typeof i === "string"))) {
                state.lastsearchterms = arr;
              }
            }
          }
        } catch (e) {
          console.log("No stored tokens: " + e.message);
        }
        state.tokens = storedtokens;

        if (storedtokens)
        {
            //console.log("Found stored tokens: " + JSON.stringify(storedtokens));

            const refreshTask = setInterval(() => this.dispatch('renewToken', state.token), 390000); // every 6,5minutes
            state.refreshTask = refreshTask;// In case you want to cancel this task on logout
            this.dispatch('renewToken');
            this.dispatch('initInstallation');
        }
      },
      setInstallations(state, arrInst)
      {
        state.installations = arrInst;
        this.dispatch("selectLastInstallation");
      },
      selectInstallation(state, pkey) {
        
        let newInsta = null;
        if (state.installations.length > 0) {
          if (pkey > 0) {
            newInsta = state.installations.find(el => el.pkey == pkey) || null;
          }
        }

        state.installation = newInsta;
        state.currentInstallation = newInsta?.pkey ?? 0;
        localStorage.setItem('currentInstallation', state.currentInstallation.toString());
        console.log("installation changed to " + pkey);
      },
      addsearchterm(state, term) {
        if (term === undefined || term === null) return;
        if (term.length == 0) return;
        if (state.lastsearchterms.includes(term)) return;
        let arr = state.lastsearchterms;
        arr.unshift(term);
        if (arr.length > 5) arr.pop();
        localStorage.setItem('lastsearchterms', JSON.stringify(arr));
        state.lastsearchterms = arr;
      },
      beginsearch(state, searchquery) {
        state.search = { searchrunning: true, searchquery: searchquery, foundfeeds: [], foundfeedsinhistory: [], foundsensors: [] }
      },
      search(state, searchObj) {
        state.search = searchObj;
      },
      seterror(state, errobj)
      {
        state.lasterr = errobj;
      },
      setaccount(state, accountObj)
      {
        state.account = accountObj;
      },
      setfeeds(state, feedsArr)
      {
        state.feeds = feedsArr;
      },
      setcharts(state, chartsArr)
      {
        state.charts = chartsArr;
      },
      setgateways(state, gwArr) {
        state.gateways = gwArr;
      },
      setsensors(state, sensorsArr)
      {
        /*let allids = sensorsArr.map(sen => {sen.plantid ?? ""});
        allids = allids.filter(s => s.length > 0);
        let uniqueids = [...new Set(allids)];
        */
        //let allids = sensorsArr.map(sen => {sen.plantid ?? ""});
        //allids = allids.filter(s => s.length > 0);

        state.sensors = sensorsArr;
        //console.log("Sensors mutated " + sensorsArr.length);
        //state.plantgroupedsensors = uniqueids;
      },
      settokens(state, newtokens)
      {
        if (newtokens) {
          console.log("Tokens changed");
        }
        else {
          console.log("Tokens invalidated");
        }
        
        localStorage.setItem('tokens', JSON.stringify(newtokens));
        state.tokens = newtokens;

        // if there is a timeout task, cancel it
        if (state.refreshTask) {
            //console.log("stop previous timer");
            clearInterval(state.refreshTask);
            state.refreshTask = null;
        }

        if (newtokens) {
          const refreshTask = setInterval(() => this.dispatch('renewToken'), 390000); // every 6,5minutes
          state.refreshTask = refreshTask;// In case you want to cancel this task on logout
          
          console.log("Requesting installation details");
          this.dispatch('initInstallation');
        }
      },
      clear(state)
      {
        state.account = null;
        state.currentInstallation = 0;
        state.installations = [];
        state.installation = null;
        state.lasterr = null;
        state.feeds = [];
        state.charts = [];
        state.sensors = [];
        state.gateways = [];
        state.lastsearchterms = [];
        state.search = { searchrunning: false, searchquery: '', foundfeeds: [], foundfeedsinhistory: [], foundsensors: [] };
        state.devices = [];
      },
      setdevices(state, devicesArr) {
        state.devices = devicesArr;
      },
      setmenupinned(state, ispinned) {
        state.ismenupinned = ispinned;
        localStorage.setItem('ismenupinned', ispinned.toString());
      }
    },
    actions : {
        logout (context) {
          console.log("Bye!");
          context.commit("settokens");
          context.commit("clear");
          router.push('/login'); // no token, go to login
        },
        renewToken(context) {
            if (context.getters.refreshtoken.length == 0) {
              console.log("skipped renewToken, because no refresh-token available");
              return; // 
            }

            const restService = useRestApi();

            restService.refreshToken().then((response) => {
                context.commit("settokens", response);
              })
              .catch(function (error) {
                if (error.response === undefined) {
                  console.error(JSON.stringify(error));
                }
                else {
                  console.warn("failed to renew token: status " + error.response.status + ". " +JSON.stringify(error.response.data));
                  if (error.response.status == 401)
                  {
                    router.push('/login'); // no token, go to login
                  }
                }
              });
        },
        search(context, txt) {
          console.log("Searching for... " + txt);
          context.commit("beginsearch", txt);
          context.commit("addsearchterm", txt);

          let searchObj = { searchrunning: false, searchquery: txt, foundfeeds: [], foundfeedsinhistory: [], foundsensors: [] };

          router.push({name:"search", params: {query: txt} });
          const restService = useRestApi();
          restService.seachFeeds(context.state.currentInstallation, txt).then((response) => {
            //console.log("Server responded OK to seachFeeds: " + JSON.stringify(response));
            searchObj.foundsensors = context.getters.lookupSensorsMatchingText(txt);
            searchObj.foundfeeds = store.getters.lookupFeedMatchingText(txt);
            
            if (searchObj.foundfeeds.length > 0) {
              response.forEach(f => {
                let fAlreadyIn = searchObj.foundfeeds.find(a => a.pkey === f.pkey);
                if (!fAlreadyIn) {
                  searchObj.foundfeedsinhistory.push(f);
                }
              });
            }
            else {
              searchObj.foundfeeds = response;
            }
            context.commit("search", searchObj); // complete the search
          }).catch((error) => {
            processErrorResponseWithToast(error, context);
            context.commit("search", searchObj); // complete the search
          });
        },
        refreshFeeds(context) {
          if (context.getters.isready) {
            const restService = useRestApi();
            restService.getFeeds(context.state.currentInstallation).then((response) => {
              //console.log("Server responded OK to getFeeds: " + JSON.stringify(response));
              context.commit("setfeeds", response);
            }).catch((error) => processErrorResponseWithToast(error, context));
          }
        },
        refreshCharts(context) {
          if (context.getters.isready) {
            const restService = useRestApi();
            restService.getCharts(context.state.currentInstallation).then((response) => {
              context.commit("setcharts", response);
            }).catch((error) => processErrorResponseWithToast(error, context));
          }
        },
        refreshSensors(context) {
          if (context.getters.isready) {
            const restService = useRestApi();
            restService.getSensors(context.state.currentInstallation).then((response) => {
              //console.log("Server responded OK to getSensor: " + JSON.stringify(response));
              context.commit("setsensors", response);
            }).catch((error) => processErrorResponseWithToast(error, context));
          }
        },
        requireSensors(context) {
          if (context.getters.isready && context.getters.sensors.length == 0) {
            context.dispatch("refreshSensors");
          }
        },
        refreshGateways(context) {
          if (context.getters.isready) {
            const restService = useRestApi();
            restService.getGateways(context.state.currentInstallation).then((response) => {
              //console.log("Server responded OK to getSensor: " + JSON.stringify(response));
              context.commit("setgateways", response);
            }).catch((error) => processErrorResponseWithToast(error, context));
          }
        },
        refreshDevices(context) {
          if (context.getters.isready) {
            const restService = useRestApi();
            restService.getDevices(context.state.currentInstallation).then((response) => {
              context.commit("setdevices", response);
            }).catch((error) => processErrorResponseWithToast(error, context));
          }
        },
        changeinstallation(context, pkey) {
          
          if (context.getters.installationpkey != pkey) {
            console.log("Switching installation from " + context.getters.installationpkey + " to " + pkey );
            this.commit("selectInstallation", pkey);
            this.commit("setfeeds", []);
            this.commit("setcharts", []);
            this.commit("setsensors", []);
            this.commit("setgateways", []);
            this.commit("setdevices", []);
            this.dispatch("refreshFeeds");
            this.dispatch("refreshCharts");
            this.dispatch("refreshSensors");
            this.dispatch("refreshGateways");
            this.dispatch("refreshDevices");
            router.replace('/');
          }
        },
        selectLastInstallation(state) { // selects the last mode installation or the first on the list if there is no last mode
          let installationkey = 0; // select 0 if there are no installations
          let instarr = state.getters.installations;
          let lastmodekey = state.getters.installationlastmodepkey;

          if (instarr.length > 0) {
            let foundInst = null;
            if (lastmodekey > 0) {
              foundInst = instarr.find(el => el.pkey == lastmodekey) || null;
            }
            
            if (foundInst) {
              installationkey = foundInst.pkey;
            }
            else {
              installationkey = instarr[0].pkey;
            }
          }
          this.commit("selectInstallation", installationkey);
        },
        initInstallation(context) {
          if (context.getters.isloggedin) {
            const restService = useRestApi();

            if (context.state.installation == null || context.state.currentInstallation == 0 || context.state.length == 0) {
              restService.getInstallations().then((response) => {
                  //console.log("Server responded OK to getInstallations: " + JSON.stringify(response));
                  context.commit("setInstallations", response);
              }).catch((error) => processErrorResponseWithToast(error, context));
            }

            if (!context.getters.account.valid) {
              restService.getAccount().then((response) => {
                context.commit("setaccount", response);
              }).catch((error) => processErrorResponseWithToast(error, context));
            }

          }
        }
    }

  });

export default store;
