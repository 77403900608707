<template>
    <div class="p-0 m-0 flex align-items-stretch h-full">
        <div class="flex flex-1 flex-column justify-content-evenly align-items-center align-content-between " >
            
            <div class="flex flex-column w-4">
                <div class="flex">
                    <img src="../assets/logo-lg.svg" class="loginlogo" >
                </div>

                <div class="flex mt-4">
                    <span class="p-float-label w-full">
                        <InputText id="username" v-model="m_Username" @keyup.enter="submitLogin" class="w-full" />
                        <label for="username">Benutzername</label>
                    </span>
                </div>

                <div class="flex my-4" >
                    <span class="p-float-label w-full">
                        <Password ref="passwordInput" id="password" :feedback="false" v-model="m_Password" @keyup.enter="submitLogin" class="w-full" />
                        <label for="password">Passwort</label>
                    </span>
                </div>

                <div class="flex mt-1">
                    <Button label="Log In" class="w-full" @click="submitLogin" />
                </div>
            </div>

            <div class="align-self-center flex align-items-center justify-content-center">
                &copy; LEHNER GmbH SENSOR-SYSTEME
            </div>
        </div>


        <div class="hidden md:inline-flex flex-1 align-items-center justify-content-center login-right-side " >

        </div>

        <Toast />
    </div>

</template>


<script>
import { ref, onMounted, watch} from  "vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useRestApi } from "../RESTService";

export default ({
    name: "LogIn",
    components: {
        Button,
        Password,
        InputText,
        Toast
    },
    setup() {

        const router = useRouter();
        const store = useStore()
        const restService = useRestApi();
        const m_Password = ref("");
        const m_Username = ref("");
        const toast = useToast();
        const passwordInput = ref(null);

        onMounted(() => { 
            console.log("Login screen mounted");
        });

        watch(() => store.getters.lasterror, function () {
            let e = store.getters.lasterror;
            if (e.valid)
            {
                toast.add({severity: "error", 
                        summary: e.caption,
                        detail: e.message,
                        life: e.duration });
            }
        });

        const submitLogin = async () =>
        {
            console.log("Calling submitLogin");
            if (m_Username.value.length < 1)
            {
                toast.add({severity: "warn", 
                        summary: "Benutzername fehlt",
                        detail: "Bitte geben Sie Ihren Benutzernamen ein.",
                        life: 15000 });
                return;
            }

            if (m_Password.value.length < 4)
            {
                toast.add({severity: "warn", 
                        summary: "Passwortlänge zu kurz",
                        detail: "Bitte geben Sie das vollständige Passwort ein.",
                        life: 15000 });
                return;
            }

            let serverResponse = {data:""}

            console.log("Sending authorize request for user " + m_Username.value);
            restService.authorize(m_Username.value, m_Password.value, serverResponse).then((response) => {
                //console.log("Server responded" + JSON.stringify(response));

                if (response.accesstoken !== undefined)
                {
                    store.commit("settokens", response);

                    console.log("Logged in!!!");
                    router.push('/');
                }
                else 
                {
                    console.error("Error: response.accesstoken is undefined");
                }
            })
            .catch(function (error) {
                if (error.response !== undefined)                 {
                    if (error.response.status == 401)
                    {
                        toast.add({severity: "warn", 
                                summary: "Anmeldung fehlgeschlagen",
                                detail: "Die Kombination aus Benutzernamen und Passwort ist nicht bekannt.",
                                life: 15000 });
                    }
                    else {
                        toast.add({severity: "error", 
                                summary: "Die Anmeldung ist nicht möglich",
                                detail: "Ein Fehler ist Aufgetreten. Der Server meldet status " + error.response.status,
                                life: 15000 });
                        /*console.log("Server responded "+error.response.status);
                        console.log(error.response.data);
                        console.log(error.response.headers);*/
                    }
                }
            });
            
        }

       return {m_Username, m_Password, submitLogin, passwordInput};
    },
})
</script>

<style scoped>

.loginlogo
{
    height: 8rem;
}

.login-right-side {
    background-image: url("../assets/login_backgr.webp") ;
    background-repeat: no-repeat;
    background-size: cover; /* Resize the background image to cover the entire container */
}

::v-deep(.p-password-input) {
    width: 100%;
}

</style>
